import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, shareReplay, switchMap, timer } from 'rxjs';
import { FeatureModel } from '../../models/FeatureModel';
import { environment } from '../../../../../portal/src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  private features$: Observable<FeatureModel[]>;

  constructor(private http: HttpClient) { 
    this.features$ = timer(0, environment.featureFlagRefreshTimeMs)
      .pipe(
        switchMap(() => this.http.get<FeatureModel[]>('api/FeatureFlag').pipe(
          catchError(() => {
            console.error("An error occurred retrieving feature flags");
            return of([])
          }))
        ),
        map(features => features.map(feature => 
          ({
            name: feature.name,
            isEnabled: feature.isEnabled
        } as FeatureModel))),
        shareReplay(1)
      );
  }

  getFeatureFlags(): Observable<FeatureModel[]> {
    return this.features$;
  }

  // Initialises the list of flags - if no user, will init for anonymous user
  public initialize(): Promise<void> {
    // Kick off a subscription to load features in a way that doesn't block the page load
    let initialSubscription = this.features$.subscribe(() => {
      // Unsubscribe once results are returned
      initialSubscription.unsubscribe();
    });
    return Promise.resolve();
  }

  // Create an observable that updates whenever the feature observable updates
  // Returns a boolean whether the feature is enabled or not. If the feature is not found, it will return false
  public isFeatureEnabled(featureName: string): Observable<boolean> {
    return this.features$.pipe(
      map(flags => flags?.find(x => x.name === featureName) ?? { name: featureName, isEnabled: false } as FeatureModel),
      map(x => x.isEnabled),
      shareReplay(1)
    );
  }
}
