export const FeatureFlags = {
    uiPageAdmin: 'ui-page-admin',
    uiPageApplication: 'ui-page-application',
    uiPagePublisher: 'ui-page-publisher',
    uiPageUser: 'ui-page-user',
    uiElementApplicationSaveDraft: 'ui-element-application-savedraft',
    uiPageSubscription: 'ui-page-subscription',
    uiPageSubscriptionOverview: 'ui-page-subscription-overview',
    uiPageUserInvite: 'ui-page-user-invite',
    uiPageAuditTrail: 'ui-page-audit-trail',
    uiPageMeteringReport: 'ui-page-metering-report',
    uiElementDownloadMeteringReport: 'ui-element-download-metering-report',
    uiPagePrivateOffer: 'ui-page-private-offer',
    uiElementEntraIdCredentials: 'ui-element-entra-id-credentials',
    uiElementUserManagement: 'ui-element-user-management',
    uiElementUserProfile: 'ui-element-user-profile',
    uiPageSubscriptionManagement: 'ui-page-subscription-management'
};
